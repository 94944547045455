<template>
    <section class="flow">
        <div class="row align-center flow-title"><i class="el-icon-s-order" style="font-size:22px;margin-right:8px;"></i><span>审批流程</span></div>
        <div class="flow-content">
            <div class="flow-step row" v-for="(step, s) in flowList" :key="s">
                <div class="flow-step-left row">
                    <div class="flow-state">
                        <div class="row align-center space-center circle-out">
                            <div class="circle-inner"></div>
                        </div>
                        <div v-if="s != flowList.length - 1" class="step-line"></div>
                    </div>
                    <div class="user-tips">
                        <div class="row align-center">
                            <span>{{step.stepType == 0 ? '审批' : step.stepType == 1 ? '仅执行' : '仅抄送'}}</span>
                            <span class="approve-type">{{step.reviewType == 1 ? '或签' : '全签'}}</span>
                        </div>
                        <div>{{step.reviewUsers.length}}人审批</div>
                    </div>
                </div>
                <div class="flow-step-right row flex-wrap">
                    <div class="row flow-user" v-for="(user, u) in step.reviewUsers" :key="u">
                        <div class="avat-name">
                            <div class="avator">
                                <el-image class="avator-img" v-if="user.avatar" :src="user.avatar"></el-image>
                                <div class="row align-center space-center avator-txt" v-else>{{user.name.substring(0, 1)}}</div>
                            </div>
                            <div class="username">{{user.name}}</div>
                        </div>
                        <div class="el-icon-plus user-add"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {queryTypeFlow} from '@/libs/http/api.js'
export default {
    name: 'FlowSteps',
    props: ['flowTypeId'],
    data(){
        return{
            flowList: []
        }
    },
    created(){
          this.queryTypeFlow();
    },
    methods: {
        queryTypeFlow(){
            queryTypeFlow({
                hrFlowTypeId: this.flowTypeId
            }).then(data=>{
                this.flowList = data.flows || [];
            }).catch(err=>{
                this.$message({type:'error', message:err, duration:2000, showClose:true});
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.flow{
    background: #fff;
    padding: 0 10px 10px;
    width: 100%;
    .flow-title{
        color: #656D78;
        font-family: 'font-Medium';
        line-height: 46px;
        font-size: 18px;
    }
    .flow-content{
        .flow-step{
            margin-bottom:30px;
            .flow-step-left{
                font-family: 'font-Light';
                width: 180px;
                .user-tips{
                    margin-top:20px;
                    margin-left: 20px;
                    >div:last-child{
                        font-size: 12px;
                        color: #999;
                    }
                    .approve-type{
                        font-size:12px;
                        border: 1px solid #067CF2;
                        line-height: 16px;
                        padding: 0 6px;
                        border-radius: 2px;
                        color:#067CF2;
                        margin-left: 10px;
                    }
                }
                .flow-state{
                    margin-top:30px;
                    .circle-out{
                        width:15px;
                        height:15px;
                        background:#067CF2;
                        border-radius: 50%;
                    }
                    .circle-inner{
                        width:7px;
                        height:7px;
                        background:#fff;
                        border-radius: 50%;
                    }
                    .step-line{
                        width:1px;
                        height:calc(100% + 50px);
                        background:#ccc;
                        margin-left: 7px;
                    }
                }
            }
            .flow-step-right{
                text-align: center;
                font-family: 'font-Regular';
                flex: 1;
                .avator{
                    width:50px;
                    height:50px;
                    border-radius:50%;
                    .avator-img{
                        width:50px;
                        height:50px;
                        border-radius:50%;
                        float: left;
                    }
                    .avator-txt{
                        width:50px;
                        height:50px;
                        border-radius:50%;
                        background: #067CF2;
                        color: #fff;
                        font-size: 22px;
                    }
                }
                .username{
                    margin-top:5px;
                    color: #333;
                }
                .user-add{
                    font-size:20px;
                    margin: 20px 15px 0;
                    color: #999;
                }
                .flow-user{
                  margin-bottom: 10px;
                }
                .flow-user:last-child>div:last-child{
                    display: none;
                }
            }
        }
    }
}
</style>