<template>
  <section class="card" @click="itemClick" :style="{ background: bgColor[index] }">
    <div class="pos-state-box" v-if="item.isDefectiveSalary === 'Y' || item.hasReviewInfo === 'Y'">
      <div v-if="item.isDefectiveSalary === 'Y'" class="pos-purple">次品销售单</div>
      <div v-else class="box-item pos-blue">
        <div class="box-label">{{ '审批' }}</div>
        <div class="box-value">
          {{
            item.reviewState === '-1'
              ? '已撤回'
              : item.reviewState === '1'
              ? '已通过'
              : item.reviewState === '2'
              ? '已拒绝'
              : '待审批'
          }}
        </div>
      </div>
    </div>
    <div
      :class="[
        'card-info',
        item.id === curId && item.afterSalesType === '0' ? 'normal-card' : '',
        item.id === curId && item.afterSalesType !== '0' ? 'after-card' : ''
      ]">
      <div class="row align-center card-top">
        <span @click.stop="copyNumber(item.orderNo)">销售单号：{{ item.orderNo }}</span>
        <i @click.stop="copyNumber(item.orderNo)" class="iconfont icon-3zuidahua-3"></i>
      </div>
      <div class="card-body">
        <div class="row align-center body-row">
          <div class="row align-center">
            <span class="row-label">单据来源：</span>
            <p class="row-value">
              {{ item.source === '1' ? '手机端' : item.source === '2' ? '客户端' : '' }}
            </p>
          </div>
          <div class="row align-center" v-if="item.afterSalesType === '0'">
            <span class="row-label">订单性质：</span>
            <p class="row-value">{{ item.orderTypeTitle }}</p>
          </div>
          <div class="row align-center" v-else>
            <span class="row-label">原销售单：</span>
            <p class="row-value">{{ item.originalInnerSn }}</p>
          </div>
        </div>
        <div class="row align-center body-row">
          <div class="row align-center" v-if="item.afterSalesType === '0'">
            <span class="row-label">业绩方式：</span>
            <p class="row-value">
              {{
                item.brokerage === '1'
                  ? '个人业绩'
                  : item.brokerage === '2'
                  ? '班组业绩'
                  : item.brokerage === '3'
                  ? '分销'
                  : ''
              }}
            </p>
          </div>
          <div class="row align-center" v-else>
            <span class="row-label">总件数：</span>
            <p class="row-value total-count">
              <span>{{ item.totalCount }}</span
              >件
            </p>
          </div>
          <div class="row align-center">
            <span class="row-label">创建时间：</span>
            <p class="row-value">{{ item.createTime }}</p>
          </div>
        </div>
        <div class="row align-center body-row" v-if="item.afterSalesType === '0'">
          <div class="row align-center">
            <span class="row-label">{{ item.brokerage === '2' ? '班组人员' : '营业员' }}：</span>
            <p class="row-value" style="width: 85px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
              {{ item.shoppingGuideName }}
            </p>
          </div>
          <div class="row align-center">
            <span class="row-label">总件数：</span>
            <p class="row-value total-count">
              <span>{{ item.totalCount }}</span
              >件
            </p>
          </div>
        </div>
        <div class="row align-center body-row">
          <div class="row align-center" v-if="item.aftersalesCount && item.aftersalesCount > 0">
            <span class="row-label">产生售后次数：</span>
            <p class="row-value">{{ item.aftersalesCount }}</p>
          </div>
          <div class="row align-center" v-if="item.reviewCount && item.reviewCount > 0">
            <span class="row-label">产生审批次数：</span>
            <p class="row-value">{{ item.reviewCount }}</p>
          </div>
        </div>
        <div
          class="card-state"
          v-if="
            (invoicesType === '2' && item.afterSalesType === '0') || (item.state == '-1' && item.afterSalesType === '1')
          ">
          <img class="state-img" :src="stateIcon[item.state]" />
        </div>
      </div>
      <div class="row align-center card-bot">
        <div class="total-price">
          应收金额：<span>￥{{ item.totalAmount }}</span>
        </div>
        <div class="total-price">
          实收金额：<span>￥{{ item.realAmount }}</span>
        </div>
      </div>
    </div>
    <div
      class="card-select row"
      :class="[item.afterSalesType !== '0' ? 'after-angel' : 'normal-angel']"
      v-if="item.id === curId">
      <span class="iconfont icon-gou"></span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ordingCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    curId: {
      type: String,
      default: () => ''
    },
    invoicesType: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      stateIcon: {
        '-1': require('@/assets/images/state/cancel.png'),
        '1': require('@/assets/images/state/wpay.png'),
        '2': require('@/assets/images/state/paying.png'),
        '3': require('@/assets/images/state/finsh.png'),
        '4': require('@/assets/images/state/state-wpay.png'),
        '5': require('@/assets/images/erp/void.png')
      },
      bgColor: ['#067CF2', '#F00'],
      state: '',
      itemId: ''
    }
  },
  computed: {
    index() {
      if (this.item.afterSalesType !== '0') {
        return '1'
      } else {
        return '0'
      }
    }
  },
  methods: {
    itemClick() {
      this.$emit('choose', this.item.isChoose)
    },
    copyNumber(num) {
      let input = document.createElement('input')
      input.value = num
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message({ type: 'success', message: '复制成功！', duration: 2000, showClose: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.pos-blue {
  background-color: #2f52fc;
  .box-label {
    background: url('../assets/images/pos/pos-blue-l.png') no-repeat;
    background-size: 100% 100%;
    color: #2f52fc;
  }
  .box-value {
    color: #fff;
  }
}
.pos-orange {
  background-color: #fc8030;
  .box-label {
    background: url('../assets/images/pos/pos-orange-l.png') no-repeat;
    background-size: 100% 100%;
    color: #fc8030;
  }
  .box-value {
    color: #fff;
  }
}
.pos-purple {
  background: url('../assets/images/pos/pos-purple.png') no-repeat;
  background-size: 100% 100%;
  width: 130px;
  display: flex;
  justify-content: center;
  color: #fff;
}
.card {
  margin: 0 2px 20px;
  box-shadow: 0 0 6px 0 rgba(4, 51, 97, 0.29);
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  .pos-state-box {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    font-weight: 100;
    .box-item {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .box-label {
      width: 91px;
      position: relative;
      left: -14px;
      display: flex;
      justify-content: center;
      font-weight: 600;
    }
    .box-value {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-right: 14px;
    }
  }
  .card-info {
    background: #fff;
    margin-left: 3px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #fff;
    .card-top {
      display: flex;
      align-items: center;
      font-family: 'font-Regular';
      margin-bottom: 10px;
      cursor: pointer;
      i {
        margin-left: 5px;
      }
    }
    .card-body {
      font-family: 'font-Light';
      font-size: 14px;
      .body-row {
        > div:first-child {
          width: 160px;
        }
        line-height: 22px;
        .row-label {
          color: #999;
        }
        .row-value {
          color: #333;
        }
      }
      .total-count > span {
        color: #f8475f;
        font-family: 'font-Medium';
        font-size: 16px;
        font-weight: 600;
      }
    }
    .card-bot {
      margin-top: 10px;
      font-family: 'font-Light';
      span {
        color: #f8475f;
        font-size: 20px;
        font-family: 'font-Medium';
      }
      .total-price {
        margin-right: 20px;
      }
    }
    .card-state {
      position: absolute;
      right: 10px;
      top: 40px;
      font-size: 0;
      .state-img {
        width: 64px;
        height: 54px;
      }
    }
  }
  .normal-card {
    background: #f6faff;
    border-color: #067cf2;
  }
  .after-card {
    background: rgb(253, 246, 246);
    border-color: #f00;
  }
  .card-select {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    border-radius: 0 0 4px 0;
    .icon-gou {
      margin-top: 35px;
      margin-left: 28px;
      color: #fff;
    }
  }
  .normal-angel {
    background: linear-gradient(-45deg, #067cf2 50%, transparent 50%);
  }
  .after-angel {
    background: linear-gradient(-45deg, #f00 50%, transparent 50%);
  }
}
</style>
