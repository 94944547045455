<template>
    <dialogModule width="500px" :title="activeObject.activityName" v-if="changeVisiable" :dialogVisible="changeVisiable" @close="close">
        <span slot="close" class="el-icon-close close" @click="close"></span>
        <div slot="content" class="cont-list">
            <div v-for="(item, index) in activeObject.list" :key="index" class="row space-between align-center cont-card">
                <div class="row align-center">
                    <div class="goods-img">
                        <el-image class="el-image" v-if="item.imageList && item.imageList[0]" :preview-src-list="item.imageList" :src="item.imageList[0]"></el-image>
                        <div v-else>暂无图片</div>
                        <div v-if="item.isGift" class="row align-center space-center is-gift gift">赠品</div>
                        <div v-else class="row align-center space-center is-gift" :class="[item.isAddBuy ? 'add-buy' : 'activity']">
                          {{item.isAddBuy ? '加购' : '促销'}}
                        </div>
                    </div>
                    <div class="column space-around goods-data">
                        <div>{{item.goodsName}}</div>
                        <p>货号：{{item.spu}}</p>
                        <div>条码：{{item.sku}}</div>
                    </div>
                </div>
                <div class="attr-list">
                    <div class="row space-center align-center" v-if="item.attr">
                        <p style="margin-right:10px;" v-for="(attr, a) in item.attr.slice(0, 2)" :key="attr.code">
                            {{attr.value}}
                            <span v-if="a === 0" style="margin:0 3px;">,</span>
                        </p>
                    </div>
                    <div style="">件数：{{item.number}}</div>
                </div>
            </div>
        </div>
    </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
export default {
    props: {
        activeObject: {
            type: Object,
            default: () => null
        }
    },
    components: {
        dialogModule
    },
    data(){
        return{
            changeVisiable: true
        }
    },
    created(){

    },
    methods: {
        close(){
            this.changeVisiable = false;
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.close{
  color: #999; 
  padding: 10px; 
  margin-right: -10px; 
  cursor: pointer
}

.cont-list{
    height:50vh;
    overflow:auto;
    padding: 10px;
    .cont-card{
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding:10px;
        margin:10px;
        .goods-img{
            position:relative;
            overflow:hidden;
            width:102px;
            height:92px;
            border-radius:4px;
            .el-image{
                width:102px;
                height:92px;
                border-radius:4px;
                float:left;
            }
            div{
                width:102px;
                height:92px;
                border-radius:4px;
                text-align:center;
                line-height:92px;
                color:#999;
                background:#f5f5f5;
            }
            .is-gift{
                position:absolute;
                top:5px;
                right:-15px;
                color:#fff;
                width:60px;
                height:20px;
                transform: rotate(45deg);
                font-size:12px;
            }
        }
        .goods-data{
            margin-left:10px;
            flex:1;
            width:170px;
			height: 90px;
            div:first-child{
                font-size:18px;
                color:#333;
                margin-top:-4px;
            }
            div:last-child{
                font-size:14px;
                color:#999;
            }
            p{
                font-size:14px;
                color:#999;
                margin:4px 0;
            }
        }
        .attr-list{
            text-align:center;
            div:first-child{
                width:130px;
                border:1px solid #DEEEF9;
                background:#fff;
                color:#333;
                line-height:26px;
                border-radius:2px;
                cursor:pointer;
                margin:0 auto
            }
            div:last-child{
                color:#999;
                margin-top:10px;
            }
        }
    }
}
.activity{
	background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400) !important;
}
.gift{
	background: linear-gradient(0deg, #208AEC, #02D7F0, #00C6F7) !important;
}
.add-buy{
	background: linear-gradient(0deg, rgb(116,47,169), rgb(101,41,147), rgb(72,30,105)) !important;
}
</style>